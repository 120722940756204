.home-page {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: 50px; /* Add margin to lower the dropzone */
}

.instructions {
  text-align: left;
  margin-bottom: 20px;
}

.dropzone {
  padding: 40px; /* Increase padding to make it bigger vertically */
  border: 2px dashed #cccccc;
  border-radius: 10px;
  background: #fafafa;
  cursor: pointer;
  margin-top: 20px; /* Add margin to lower the dropzone */
}

.progress-bar {
  height: 5px;
  background-color: #4caf50;
  margin: 10px 0;
}

.file-list {
  margin: 20px 0;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #cccccc;
}

.remove-icon {
  color: red;
  cursor: pointer;
}

.upload-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #0056b3;
}

.feedback-message {
  margin-top: 20px;
  color: green;
  font-weight: bold;
}
