.map-page {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Use the full viewport height */
  }
  
  .navbar-placeholder {
    height: 30px; /* Height of the navbar to create space */
  }
  
  .content {
    display: flex;
    flex-grow: 1;
    overflow: hidden; /* Prevent scroll bar */
  }
  
  .sidebar {
    width: 300px;
    padding: 5px;
    background-color: #f8f8f8;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    height: calc(100vh - 120px); /* Ensure sidebar takes up full height minus navbar */
    overflow-y: auto; /* Allow scrolling within the sidebar */
  }
  
  .option-group {
    margin-bottom: 20px;
  }
  
  .option-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .option-group select {
    width: 100%;
    padding: 5px;
  }
  
  .map-container-full-page {
    flex-grow: 1;
    height: calc(100vh - 120px); /* Ensure the map container takes up the full height minus the navbar */
    position: relative;
    padding: 5px;
  }

  .color-theme-info {
    margin-top: 5px;
    font-size: 12px;
    color: #333;
  }
  
  