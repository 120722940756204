.legend-container {
    position: absolute;
    bottom: 30px;
    right: 20px;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .legend-title {
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .legend-gradient-vertical {
    width: 20px;
    height: 100px;
    background: linear-gradient(to top, red, white);
    margin: 5px 0;
  }
  
  .legend-labels-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }
  