/* Ensure the app takes up the full height of the viewport */
.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
}

/* Existing styles for navigation tabs */
.nav-tabs {
  list-style: none;
  padding: 0;
  display: flex;
  margin-bottom: 0px;
  background-color: rgb(255, 255, 255); /* Grey background color */
  padding: 10px; /* Add some padding */
  border-radius: 4px; /* Optional: rounded corners */
}

.nav-tabs li {
  margin-right: 10px;
}

.nav-tabs a {
  text-decoration: none;
  color: #007bff;
  padding: 10px 15px;
  border: 1px solid #007bff;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-tabs a:hover {
  background-color: #007bff;
  color: white;
}

.nav-tabs a.active-tab {
  background-color: #007bff;
  color: white;
}

/* Style for top half of the app */
.top-half {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 80%;
  margin-top: 0px; /* Ensure space from the top */
  max-height: 40vh; /* Limit the height of the top half */
  overflow: clip; /* Hide overflow for the top half */
  padding-bottom: 0px;
}

/* Style for filters and scenario selector container */
.filters-container {
  display: flex;
  flex-direction: column;
  width: 10vw;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 10vh; /* Start just below the navigation bar */
}

/* Style for bottom half of the app */
.bottom-half {
  display: flex;
  justify-content: flex-start; /* Align to the left */
  align-items: flex-start;
  flex: 1;
  width: 80vw; /* Limit the total width to 80% of the viewport width */
  position: absolute; /* Position absolute to start at a specific height */
  top: 50vh; /* Start at 50% of the viewport height */
}

/* Style for map and chart containers */
.map-chart-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 50vw; /* Limit the width to 50% of the viewport width */
}

/* Style for map container */
.map-container-dash {
  width: 100%;
  height: 300px; /* Set a fixed height for the map container */
  position: relative;
  z-index: 1; /* Ensure the map is on top */
}

/* Style for chart container */
.chart-container {
  width: 100%;
  height: 100%;
}

/* Style for table container */
.table-container {
  width: 80vw; /* Limit the width to 80% of the viewport width */
  height: 50vh; /* Limit the height to 50% of the viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal overflow */
  margin-right: 0px;
  margin-top: 60px; /* Adjust the top margin to lower the table */
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Style for inner table container */
.inner-table-container {
  flex: 1;
  width: 100%;
  overflow-x: auto; /* Always show horizontal scrollbar */
  overflow-y: hidden; /* Hide vertical overflow */
  padding-bottom: 20px; /* Add padding to show horizontal scrollbar */
  position: relative; /* Ensure it stays within the parent container */
}

/* Style for filters */
.filters {
  display: flex;
  flex-direction: column;
}

/* Style for filter panel */
.filter-panel {
  width: 200px;
  padding: 5px;
  border-left: 1px solid #ccc;
  margin-bottom: 10px;
}

.scenario-selector label {
  display: block;
  margin-bottom: 8px; /* Adjust the spacing as needed */
}

.scenario-selector select {
  width: 100%; /* Optional: make the select element full-width */
}

/* Style for scenario selector */
.scenario-selector {
  display: block;
  padding: 5px;
  border-left: 1px solid #ccc;
  margin-bottom: 10px;
}

/* Style for table */
table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 10px;
  text-align: left;
}


/* Style for summary page */
.summary-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Style for data table wrapper */
.data-table-wrapper {
  width: 80vw; /* Limit the width to 80% of the viewport width */
  flex: 1;
  overflow: auto;
  padding: 20px;
}

/* Style for chart container */
.chart-container {
  width: 80vw; /* Limit the width to 80% of the viewport width */
  height: 60%; /* Ensure the chart container takes up 60% of the height */
}

/* Style for navigation */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav li {
  display: inline;
  margin: 0 10px;
}

.nav-tabs {
  flex: 1;
}

nav img {
  height: 50px; /* Adjust the height as needed */
}
