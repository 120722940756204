.matrix-table-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.inner-table-container {
  flex: 1;
  overflow: hidden;
}

.handsontable .ht_clone_top, .handsontable .ht_clone_left, .handsontable .ht_clone_top_left_corner {
  z-index: 1000;
  background-color: #f4f4f4;
}

.ht-bold {
  font-weight: bold;
}

.htCenter {
  text-align: center !important;
}

.ht-middle {
  vertical-align: middle !important;
}
