.summary-table .rdt_TableHeadRow .rdt_TableCol {
    font-weight: bold;
  }
  
  /* .summary-table .rdt_TableBody .rdt_TableRow:first-child .rdt_TableCell {
    font-weight: bold;
  } */
  
  .summary-table .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child,
  .summary-table .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {
    font-weight: bold;
  }
  